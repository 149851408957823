body {
  background: linear-gradient(45deg, #BD03F7, #000000, #4F5A6C);
  color: #FFFFFF;
  margin: 0;
  height: 100vh;
  overflow: auto;
}

.dashboard {
  padding: 20px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.card-container {
  flex-grow: 1;
}

/* General card styles */
.card-header {
  background-color: #000000;
  color: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
  text-align: center; /* Centering headers */
}

.card-body {
  flex-grow: 1;
  text-align: center; /* Centering content */
}

/* Specific card styles */
.main-card {
  border: none;
  width: 100%;
  height: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.main-card .card-body {
  padding: 0;
  display: flex;
  overflow: auto;
  align-items: center;
  justify-content: center;
  position: relative;
}

.info-card, .feeds-card, .alerts-card {
  border-radius: 8px;
  background: #000000;
  color: #FFFFFF;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.info-card {
  position: absolute;
  top: 30px;
  right: 0;
  width: 10%;
  height: calc(100% - 60px);
  border: none;
}

.feeds-card {
  border: 1px solid #000000;
  border-radius: 8px;
  background: #000000;
  color: #FFFFFF;
  flex-grow: 1;
  width: 50%;
}

.alerts-card {
  border: 1px solid #000000;
  border-radius: 8px;
  background: #000000;
  color: #FFFFFF;
  flex-grow: 1;
  width: 50%;

}
.semi-transparent-frame {
  background-color: rgba(0, 0, 0, 0.5);
  border: 4px groove rgba(189, 3, 247, 0.2);
  border-radius: 8px;
}

.content-card {
  border: 1px solid rgba(189, 3, 247, 0.2);
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #FFFFFF;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 10px;
}

.content-card:last-child {
  margin-bottom: 0;
}

.content-card .card-header {
  background-color: rgba(189, 3, 247, 0.5);
  border-radius: 8px 8px 0 0;
}
